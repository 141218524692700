/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingEntity } from '../models/BookingEntity';
import type { CourseGroupEntity } from '../models/CourseGroupEntity';
import type { FormEntity } from '../models/FormEntity';
import type { InstructorEntity } from '../models/InstructorEntity';
import type { MeetingEntity } from '../models/MeetingEntity';
import type { PostCourseGroupDto } from '../models/PostCourseGroupDto';
import type { TagEntity } from '../models/TagEntity';
import type { UpdateCourseGroupDto } from '../models/UpdateCourseGroupDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CourseGroupService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all Course Groups
     * @returns CourseGroupEntity Course Groups have been returned
     * @throws ApiError
     */
    public courseGroupControllerGetCourseGroups(): CancelablePromise<Array<CourseGroupEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/courseGroup',
        });
    }
    /**
     * Create a Course Group
     * @param requestBody
     * @returns CourseGroupEntity Course Group has been created
     * @throws ApiError
     */
    public courseGroupControllerCreateCourseGroup(
        requestBody: PostCourseGroupDto,
    ): CancelablePromise<CourseGroupEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/courseGroup',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Get a Course Group
     * @param courseGroupId
     * @returns CourseGroupEntity Course Group has been returned
     * @throws ApiError
     */
    public courseGroupControllerGetCourseGroup(
        courseGroupId: string,
    ): CancelablePromise<CourseGroupEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/courseGroup/{courseGroupId}',
            path: {
                'courseGroupId': courseGroupId,
            },
            errors: {
                404: `Course Group has not been found`,
            },
        });
    }
    /**
     * Update a Course Group
     * @param courseGroupId
     * @param requestBody
     * @returns CourseGroupEntity The updated Course Group
     * @throws ApiError
     */
    public courseGroupControllerUpdateCourseGroup(
        courseGroupId: string,
        requestBody: UpdateCourseGroupDto,
    ): CancelablePromise<CourseGroupEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/courseGroup/{courseGroupId}',
            path: {
                'courseGroupId': courseGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Course Group has not been found`,
            },
        });
    }
    /**
     * Delete a Course Group
     * @param courseGroupId
     * @returns CourseGroupEntity Course Group has been deleted
     * @throws ApiError
     */
    public courseGroupControllerDeleteCourseGroup(
        courseGroupId: string,
    ): CancelablePromise<CourseGroupEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/courseGroup/{courseGroupId}',
            path: {
                'courseGroupId': courseGroupId,
            },
            errors: {
                404: `Course Group has not been found`,
            },
        });
    }
    /**
     * Get a Course Group's attendance bookings
     * @param courseGroupId
     * @returns InstructorEntity Attendance Bookings of the Course Group have been returned
     * @throws ApiError
     */
    public courseGroupControllerGetCourseGroupDefaultInstructors(
        courseGroupId: string,
    ): CancelablePromise<Array<InstructorEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/courseGroup/{courseGroupId}/instructors',
            path: {
                'courseGroupId': courseGroupId,
            },
        });
    }
    /**
     * Get a Course Group's attendance bookings
     * @param courseGroupId
     * @returns BookingEntity Attendance Bookings of the Course Group have been returned
     * @throws ApiError
     */
    public courseGroupControllerGetCourseGroupAttendanceBookings(
        courseGroupId: string,
    ): CancelablePromise<Array<BookingEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/courseGroup/{courseGroupId}/bookings/attendance',
            path: {
                'courseGroupId': courseGroupId,
            },
        });
    }
    /**
     * Get a Course Groups bookings amount
     * @param courseGroupId
     * @returns number Active Bookings of the Course Group have been returned
     * @throws ApiError
     */
    public courseGroupControllerGetCourseGroupActiveBookingsCount(
        courseGroupId: string,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/courseGroup/{courseGroupId}/bookings/active/count',
            path: {
                'courseGroupId': courseGroupId,
            },
        });
    }
    /**
     * Get all Meetings of a Course Group
     * @param courseGroupId
     * @returns MeetingEntity Course Group Meetings have been returned
     * @throws ApiError
     */
    public courseGroupControllerGetCourseGroupMeetings(
        courseGroupId: string,
    ): CancelablePromise<Array<MeetingEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/courseGroup/{courseGroupId}/meetings',
            path: {
                'courseGroupId': courseGroupId,
            },
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Get the forms of a Course Group
     * @param courseGroupId
     * @returns FormEntity Forms have been returned
     * @throws ApiError
     */
    public courseGroupControllerGetCourseGroupForms(
        courseGroupId: string,
    ): CancelablePromise<Array<FormEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/courseGroup/{courseGroupId}/forms',
            path: {
                'courseGroupId': courseGroupId,
            },
            errors: {
                404: `Course Group has not been found`,
            },
        });
    }
    /**
     * Get the tags of a course group
     * @param courseGroupId
     * @returns TagEntity Tags have been returned
     * @throws ApiError
     */
    public courseGroupControllerGetCourseGroupTags(
        courseGroupId: string,
    ): CancelablePromise<Array<TagEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/courseGroup/{courseGroupId}/tags',
            path: {
                'courseGroupId': courseGroupId,
            },
            errors: {
                404: `Tags has not been found`,
            },
        });
    }
}
