import { useQuery } from '@tanstack/react-query'
import { InstructorEntity } from '../../../qourses-api-client'
import { qoursesApiInstructor } from '@/api/qourses-instructor.tsx'

export default function useGetCustomerInstructor(): {
  instructor: InstructorEntity
  isLoading: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: instructor,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(['instructor', 'customer'], async () => {
    return qoursesApiInstructor.profileCustomer.profileCustomerControllerGetInstructor()
  })

  return { instructor, isLoading, isError, refetch, isRefetching }
}
