import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shadcn/components/ui/dialog.tsx'
import { useEffect, useState } from 'react'
import { Calendar, CalendarClock, CalendarRange, Loader2 } from 'lucide-react'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { popAllModals } from '@/components/modals/index.tsx'
import { useQueryClient } from '@tanstack/react-query'
import Dynamic from '@/components/modals/dynamic.tsx'
import { DateSelect } from '@/components/formInputs/DateSelect.tsx'
import { DateTime } from 'luxon'
import {
  minDelay,
  removePointerEventsFromBody,
  sendNotification,
  ToastVariant,
} from '@/utils.tsx'
import CourseScheduleSelection from '@/components/entityDropdowns/CourseScheduleSelection.tsx'
import { CourseScheduleEntity } from '../../../qourses-api-client'
import { qoursesApi } from '@/api/qourses.tsx'
import { CourseName } from '@/components/Resolvers.tsx'
import { getScheduleBatchesQueryKey } from '@/hooks/schedules/useGetScheduleBatches.tsx'
import { RadioGroup, RadioGroupItem } from '@/shadcn/components/ui/radio-group.tsx'
import useGetCourseSchedules, { GetCourseSchedules } from '@/hooks/courses/useGetCourseSchedules.tsx'

export function CreateScheduleBatchModal({
  courseId,
  courseScheduleId,
}: {
  courseId: string
  courseScheduleId?: string
}) {
  const { t: translate } = useTranslation()
  const queryClient = useQueryClient()

  const {schedules, isLoading} = useGetCourseSchedules(courseId)
  const [evaluatedStartDateForAllCourseSchedules, setEvaluatedStartDateForAllCourseSchedules] = useState<DateTime>()
  const [selectedSchedule, setSelectedSchedule] = useState<CourseScheduleEntity>()
  const [allCourseSchedulesSelected, setAllCourseSchedulesSelected] =
    useState<boolean>(!courseScheduleId)

  // Prevent radix from creating a sticky pointer events none and breaking the page
  removePointerEventsFromBody()

  useEffect(() => {
    if (allCourseSchedulesSelected && schedules.length > 0) {
      // We want to calculate the earliest start date here in case the user proceeds with teh allCoursesSchedulesSelected mode
      // To do this we respect either:
      // * if all courseSchedules have an evaluatedUntil date, we take the earliest one
      // * there is a courseSchedule without an evaluatedUntil date, we take the earliest startDate
      // lastly we check if the chosen date is earlier than now and fast forward to now if it is

      // Check if all courseSchedules have evaluatedUntil dates
      const allHaveEvaluatedUntil = schedules.every(
        (schedule) => schedule.evaluatedUntil !== null,
      )

      let calculatedStartDate: DateTime
      if (allHaveEvaluatedUntil) {
        // If all have evaluatedUntil, sort schedules by it
        const sortedSchedules = schedules.sort((a, b) => {
          return (
            DateTime.fromISO(a.evaluatedUntil).toMillis() -
            DateTime.fromISO(b.evaluatedUntil).toMillis()
          )
        })
        // we find the schedule that has the earliest evaluatedUntil date. If the schedule has an endDate - we require the evaluatedUntil
        // to be before it - this effectively removes schedules that are already finished
        const earliestSchedule = sortedSchedules.find((schedule) => {
          if (schedule.endDate) {
            if (
              DateTime.fromISO(schedule.evaluatedUntil) <
              DateTime.fromISO(schedule.endDate)
            ) {
              return schedule
            }
          } else {
            return schedule
          }
        })

        // !! We add one day - as we want to start the batch on the day after the last evaluatedUntil date !!
        calculatedStartDate = DateTime.fromISO(earliestSchedule.evaluatedUntil)
      } else {
        // If any schedule lacks evaluatedUntil, find earliest startDate
        calculatedStartDate = schedules.reduce((earliest, current) => {
          return DateTime.fromISO(current.startDate) < earliest
            ? DateTime.fromISO(current.startDate)
            : earliest
        }, DateTime.fromISO(schedules[0].startDate))
      }
      // Check if the calculatedStartDate is earlier than now
      if (calculatedStartDate < DateTime.now()) {
        calculatedStartDate = DateTime.now()
      }
      changeStartDate(calculatedStartDate)
      setEvaluatedStartDateForAllCourseSchedules(calculatedStartDate)
    }
  }, [allCourseSchedulesSelected, schedules])

  useEffect(() => {
    if (selectedSchedule && selectedSchedule.evaluatedUntil) {
      // !! We add one day - as we want to start the batch on the day after the last evaluatedUntil date !!
      changeStartDate(DateTime.fromISO(selectedSchedule.evaluatedUntil))
    } else if(selectedSchedule && selectedSchedule.startDate) {
      changeStartDate(DateTime.fromISO(selectedSchedule.startDate))
    }
  }, [selectedSchedule, isLoading])

  const [startDate, setStartDate] = useState<DateTime>()
  const [endDate, setEndDate] = useState<DateTime>()

  const [submitting, setSubmitting] = useState(false)

  function changeStartDate(date: DateTime) {
    setStartDate(date)
    if (endDate && date > endDate) {
      changeEndDate(null)
    }
  }

  function changeEndDate(date: DateTime) {
    setEndDate(date ? date.endOf('day') : null)
  }

  async function handleCreateScheduleBatch() {
    try {
      setSubmitting(true)

      if (allCourseSchedulesSelected) {
        // build array of courseSchedules we want to create batches for
        // we need to save the start and end date for each schedule as they're limited by their own configured
        // evaluatedUntil dates, startDates & endDates
        // some schedules we might not even create batches for if they fall out of the given time range
        const courseSchedulesWithDates: Array<{
          id: string
          startDate: DateTime
          endDate: DateTime
        }> = []
        for (const schedule of schedules) {
          let startDateForSchedule: DateTime
          let endDateForSchedule: DateTime

          // first we set the end date to the schedule's endDate if it exists and is before the user's given endDate
          if (schedule.endDate && DateTime.fromISO(schedule.endDate) < endDate) {
            endDateForSchedule = DateTime.fromISO(schedule.endDate)
          } else {
            endDateForSchedule = endDate
          }

          // we check if the schedule already has an evaluatedUntil date
          if (schedule.evaluatedUntil) {
            // if the evaluatedUntil date is before the user's given startDate, we can respect the user's choice
            if (DateTime.fromISO(schedule.evaluatedUntil) < startDate) {
              startDateForSchedule = startDate
            } else {
              // NOTICE: the date could be after the user's given endDate, but we check it later on
              // we add one day to the evaluatedUntil date as we want to start the batch on the day after the last evaluatedUntil date
              startDateForSchedule = DateTime.fromISO(schedule.evaluatedUntil)
            }
          } else {
            // we check if the schedule has a startDate after the user's given startDate
            if (DateTime.fromISO(schedule.startDate) > startDate) {
              startDateForSchedule = DateTime.fromISO(schedule.startDate)
            } else {
              startDateForSchedule = startDate
            }
          }

          // if the schedule has startDateForSchedule after the endDateForSchedule, we skip the courseSchedule entirely - there must have been one in the past
          if (startDateForSchedule > endDateForSchedule) {
            /* console.log(
              'startDateForSchedule ',
              startDateForSchedule.toLocaleString(DateTime.DATETIME_FULL),
            )
            console.log(
              'endDateForSchedule ',
              endDateForSchedule.toLocaleString(DateTime.DATETIME_FULL),
            )*/
            continue
          }

          courseSchedulesWithDates.push({
            id: schedule.id,
            startDate: startDateForSchedule,
            endDate: endDateForSchedule,
          })
        }

        if (courseSchedulesWithDates.length === 0) {
          sendNotification(
            translate('modals.createScheduleBatchModal.errorState.title'),
            translate('modals.createScheduleBatchModal.errorState.subtitle'),
            ToastVariant.Error,
          )
          return
        }

        // then we call the API for each schedule - we will also have a minDelay but for all schedules combined
        await minDelay(
          Promise.all(
            courseSchedulesWithDates.map((courseScheduleWithDates) =>
              qoursesApi.scheduling.schedulingControllerCreateScheduleBatch({
                courseScheduleId: courseScheduleWithDates.id,
                startDate: courseScheduleWithDates.startDate.toISO(),
                endDate: courseScheduleWithDates.endDate.toISO(),
              }),
            ),
          ),
          800,
        )

        sendNotification(
          translate('modals.createScheduleBatchModal.notification.title', {
            count: courseSchedulesWithDates.length,
          }),
          translate('modals.createScheduleBatchModal.notification.subtitle'),
          ToastVariant.Success,
        )
      } else {
        await minDelay(
          qoursesApi.scheduling.schedulingControllerCreateScheduleBatch({
            courseScheduleId: selectedSchedule.id,
            startDate: startDate.toISO(),
            endDate: endDate ? endDate.toISO() : undefined,
          }),
          800,
        )

        sendNotification(
          translate('modals.createScheduleBatchModal.notification.title', {
            count: 1,
          }),
          translate('modals.createScheduleBatchModal.notification.subtitle'),
          ToastVariant.Success,
        )
      }


      await queryClient.invalidateQueries(GetCourseSchedules(courseId))
      await queryClient.invalidateQueries(getScheduleBatchesQueryKey())
      await queryClient.invalidateQueries(['courses', 'schedule', courseId])

      setSubmitting(false)
      popAllModals()
    } catch (error) {
      console.error(error)
      sendNotification(
        translate('modals.createScheduleBatchModal.errorState.title'),
        translate('modals.createScheduleBatchModal.errorState.subtitle'),
        ToastVariant.Error,
      )
      setSubmitting(false)
    }
  }

  return (
    <Dynamic.Content className="p-4 sm:p-8">
      <DialogHeader className="mb-2">
        <DialogTitle className="mt-6 sm:mt-2"></DialogTitle>
        <DialogTitle className="mt-6 flex flex-col gap-y-2 sm:mt-2">
          <div className="flex flex-row items-center justify-center text-sm text-muted-foreground sm:justify-start">
            <Calendar className="mr-1 size-4 text-muted-foreground" />
            <CourseName courseId={courseId} />
          </div>
          <p>{translate('modals.createScheduleBatchModal.title')}</p>
        </DialogTitle>
        <DialogDescription>
          {translate('modals.createScheduleBatchModal.subtitle')}
        </DialogDescription>
      </DialogHeader>
      <div className="px-2">
        <label className="mb-2 flex items-center text-sm font-medium text-gray-700">
          <CalendarRange className="mr-1 size-3" />
          {translate('modals.createScheduleBatchModal.schedule.label')}
        </label>
        <RadioGroup
          value={allCourseSchedulesSelected ? 'all' : 'specific'}
          onValueChange={(value: string) =>
            setAllCourseSchedulesSelected(value === 'all')
          }
          className="my-2 text-sm"
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="all" id="all" />
            <label htmlFor="all">
              {translate('modals.createScheduleBatchModal.schedule.radio.all')}
            </label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="specific" id="specific" />
            <label htmlFor="specific">
              {translate('modals.createScheduleBatchModal.schedule.radio.specific')}
            </label>
          </div>
        </RadioGroup>

        <AnimatePresence>
          {!allCourseSchedulesSelected && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{
                duration: 0.2,
                ease: 'easeInOut',
              }}
              style={{ overflow: 'hidden' }}
              className="ml-6"
            >
              <CourseScheduleSelection
                preselectedScheduleId={courseScheduleId}
                selectedSchedule={selectedSchedule}
                setSelectedSchedule={setSelectedSchedule}
                courseId={courseId}
                disabled={false}
                disableFinishedSchedules={true}
              />
              <p className="mt-1 text-sm text-muted-foreground">
                {translate('modals.createScheduleBatchModal.schedule.subtitle')}
              </p>
              {selectedSchedule &&
                selectedSchedule.endDate &&
                !selectedSchedule.evaluatedUntil && (
                  <Button
                    variant={'secondary'}
                    className="mt-4"
                    disabled={!selectedSchedule}
                    onClick={() => {
                      if (selectedSchedule.endDate) {
                        changeEndDate(DateTime.fromISO(selectedSchedule.endDate))
                      }
                      changeStartDate(DateTime.fromISO(selectedSchedule.startDate))
                    }}
                  >
                    <CalendarClock className="mr-2 h-4 w-4" />
                    {translate('modals.createScheduleBatchModal.schedule.maxRangeButton')}
                  </Button>
                )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="mt-2 space-y-6 rounded-lg bg-gray-50 p-3 ring-1 ring-gray-100">
        <label className="mb-2 flex items-center text-sm font-medium text-gray-700">
          <Calendar className="mr-1 size-3" />
          {translate('modals.createScheduleBatchModal.schedule.calendarTitle')}
        </label>
        <div>
          <DateSelect
            focusMonth={startDate ?? undefined}
            disabled={!allCourseSchedulesSelected && !selectedSchedule}
            calendarDisabledMatcher={{
              before:
                selectedSchedule && selectedSchedule.evaluatedUntil
                  ? DateTime.fromISO(selectedSchedule.evaluatedUntil).toJSDate()
                  : selectedSchedule && selectedSchedule.startDate
                    ? DateTime.fromISO(selectedSchedule.startDate).toJSDate()
                    : null,
              after:
                selectedSchedule && selectedSchedule.endDate
                  ? DateTime.fromISO(selectedSchedule.endDate).toJSDate()
                  : null,
            }}
            startDate={startDate}
            setStartDate={changeStartDate}
            title={translate('modals.createScheduleBatchModal.startDate.label')}
            placeholder={translate(
              'modals.createScheduleBatchModal.startDate.placeholder',
            )}
            label={
              allCourseSchedulesSelected
                ? translate('modals.createScheduleBatchModal.startDate.allCourseSchedulesSubtitleEvaluated',
                  {
                    date: evaluatedStartDateForAllCourseSchedules && evaluatedStartDateForAllCourseSchedules.toLocaleString(DateTime.DATE_SHORT)
                  })
                : (selectedSchedule && selectedSchedule.evaluatedUntil)
                  ? translate(
                      'modals.createScheduleBatchModal.startDate.subtitleEvaluated',
                      {
                        date: DateTime.fromISO(
                          selectedSchedule.evaluatedUntil,
                        ).toLocaleString(DateTime.DATE_SHORT),
                      },
                    )
                  : translate('modals.createScheduleBatchModal.startDate.subtitle')
            }
          />
        </div>
        <div>
          <DateSelect
            focusMonth={endDate ?? startDate ?? undefined}
            calendarDisabledMatcher={[
              !startDate,
              {
                before: startDate
                  ? startDate.toJSDate()
                  : selectedSchedule && selectedSchedule.evaluatedUntil
                    ? DateTime.fromISO(selectedSchedule.evaluatedUntil).toJSDate()
                    : selectedSchedule && selectedSchedule.startDate
                      ? DateTime.fromISO(selectedSchedule.startDate).toJSDate()
                      : null,
                after:
                  selectedSchedule &&
                  selectedSchedule.endDate &&
                  DateTime.fromISO(selectedSchedule.endDate).toJSDate(),
              },
            ]}
            disabled={!allCourseSchedulesSelected && !selectedSchedule}
            startDate={endDate}
            setStartDate={changeEndDate}
            title={translate('modals.createScheduleBatchModal.endDate.label')}
            placeholder={translate('modals.createScheduleBatchModal.endDate.placeholder')}
            label={
              selectedSchedule && !allCourseSchedulesSelected && selectedSchedule.endDate
                ? translate('modals.createScheduleBatchModal.endDate.subtitleLimited', {
                    date: DateTime.fromISO(selectedSchedule.endDate).toLocaleString(
                      DateTime.DATE_SHORT,
                    ),
                  })
                : translate('modals.createScheduleBatchModal.endDate.subtitle')
            }
          />
        </div>
      </div>
      <div className="mt-4 flex flex-col justify-end">
        <Button
          variant="indigo"
          onClick={handleCreateScheduleBatch}
          disabled={!allCourseSchedulesSelected && !selectedSchedule || (!startDate || !endDate)}
        >
          {submitting ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {translate('common.loading')}
            </>
          ) : (
            translate('modals.createScheduleBatchModal.button')
          )}
        </Button>
      </div>
    </Dynamic.Content>
  )
}
