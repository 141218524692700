import { useQuery } from '@tanstack/react-query'
import { qoursesApi } from '../../api/qourses.tsx'
import { PublicOrganizationEntity } from '../../../qourses-api-client'

export default function useGetOrganizationPublic(organizationId: string): {
  organization: PublicOrganizationEntity
  isLoading: boolean
  isError: boolean
} {
  const {
    data: organization,
    isLoading,
    isError,
  } = useQuery(['public', 'organization'], async () => {
    return qoursesApi.organizationPublic.organizationPublicControllerGetPublicOrganization(
      organizationId,
    )
  })

  if (organization === undefined) {
    return { organization: {} as PublicOrganizationEntity, isLoading, isError }
  }

  return { organization, isLoading, isError }
}
