import { useQuery } from '@tanstack/react-query'
import { qoursesApi } from '../../api/qourses.tsx'
import { CourseGroupEntity } from '../../../qourses-api-client'

export default function useGetCourseGroup(courseGroupId: string): {
  courseGroup: CourseGroupEntity
  isLoading: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: courseGroup,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(['courseGroup', courseGroupId], async () => {
    return qoursesApi.courseGroup.courseGroupControllerGetCourseGroup(courseGroupId)
  })

  return { courseGroup, isLoading, isError, refetch, isRefetching }
}
