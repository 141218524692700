import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shadcn/components/ui/dialog.tsx'
import { useTranslation } from 'react-i18next'
import { Loader2, Signature } from 'lucide-react'
import { Button } from '@/shadcn/components/ui/button.tsx'
import {
  minDelay,
  sendGenericErrorNotification,
  sendNotification,
  ToastVariant,
} from '@/utils.tsx'
import { useState } from 'react'
import Dynamic from '@/components/modals/dynamic.tsx'
import { Label } from '@/shadcn/components/ui/label.tsx'
import { Input } from '@/shadcn/components/ui/input.tsx'
import { qoursesApi } from '@/api/qourses.tsx'
import { popAllModals } from '@/components/modals/index.tsx'

export default function UpdateCustomerNameModal({ forceUpdate }: { forceUpdate?: boolean }) {
  const [isLoading, setLoading] = useState(false)

  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')

  const { t: translate } = useTranslation()

  const handleUpdateCustomerName = async () => {
    try {
      setLoading(true)
      await minDelay(
        qoursesApi.profileCustomer.profileCustomerControllerPatchProfile({
          firstName: name,
          lastName: lastName,
        }),
        1000,
      )
      sendNotification(
        translate('modals.updateCustomerName.notification.title'),
        translate('modals.updateCustomerName.notification.subtitle'),
        ToastVariant.Success,
      )
      popAllModals()
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } catch (e) {
      console.error(e)
      sendGenericErrorNotification()
      sendNotification(
        translate('modals.updateCustomerName.errorState.title'),
        translate('modals.updateCustomerName.errorState.subtitle'),
        ToastVariant.Success,
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dynamic.Content className="p-6 sm:p-8">
      <DialogHeader className="mb-2">
        <DialogTitle className="mt-6 sm:mt-2">
          {forceUpdate
            ? translate('modals.updateCustomerName.titleForce')
            : translate('modals.updateCustomerName.title')}
        </DialogTitle>
        <DialogDescription>
          {forceUpdate
            ? translate('modals.updateCustomerName.subtitleForce')
            : translate('modals.updateCustomerName.subtitle')}
        </DialogDescription>
      </DialogHeader>
      <div className="mb-8 mt-4 space-y-8">
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="name" className="mb-1">
            {translate('modals.updateCustomerName.name.label')}
          </Label>
          <Input
            id="name"
            value={name}
            placeholder={translate('modals.updateCustomerName.name.placeholder')}
            className="col-span-3"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="name" className="mb-1">
            {translate('modals.updateCustomerName.lastName.label')}
          </Label>
          <Input
            value={lastName}
            id="setLastName"
            placeholder={translate('modals.updateCustomerName.lastName.placeholder')}
            className="col-span-3"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>
      <Button
        variant="indigo"
        disabled={isLoading || !name || !lastName}
        onClick={handleUpdateCustomerName}
      >
        {isLoading ? (
          <>
            {translate('common.loading')}
            <Loader2 className="ml-2 h-4 w-4 animate-spin" />
          </>
        ) : (
          <>
            {translate('modals.updateCustomerName.button')}
            <Signature className="ml-2 h-4 w-4" />
          </>
        )}
      </Button>
    </Dynamic.Content>
  )
}
