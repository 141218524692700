import { useTranslation } from 'react-i18next'
import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shadcn/components/ui/dialog.tsx'
import { TimePicker } from '@/shadcn/components/ui/time-picker-hours-and-minutes.tsx'
import { useEffect, useState } from 'react'
import { Label } from '@/shadcn/components/ui/label.tsx'
import { CalendarIcon, Loader2, Undo2, X } from 'lucide-react'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { useQueryClient } from '@tanstack/react-query'
import Dynamic from '@/components/modals/dynamic.tsx'
import useGetBooking from '@/hooks/orders/useGetBooking.tsx'
import PriceInput from '@/components/formInputs/PriceInput.tsx'
import { cn } from '@/shadcn/lib/utils.ts'
import { DateTime } from 'luxon'
import { Calendar } from '@/shadcn/components/ui/calendar.tsx'
import { de, enUS } from 'date-fns/locale'
import i18n from 'i18next'
import { minDelay, removePointerEventsFromBody, sendNotification, ToastVariant } from '@/utils.tsx'
import { qoursesApi } from '@/api/qourses.tsx'
import { GetOrderBookingsQueryKey } from '@/hooks/orders/useGetOrderBookings.tsx'
import { popAllModals } from '@/components/modals/index.tsx'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/shadcn/components/ui/popover.tsx'

export function UpdateOrganizationalBookingModal({ bookingId }: { bookingId: string }) {
  const { t: translate } = useTranslation()
  const locale = i18n.language.includes('de') ? de : enUS

  // Prevent radix from creating a sticky pointer events none and breaking the page
  removePointerEventsFromBody()

  const { booking, isLoading, isError } = useGetBooking(bookingId)

  const queryClient = useQueryClient()

  const [exclusiveUntil, setExclusiveUntil] = useState<DateTime | undefined>()
  const [disableExclusiveUntil, setDisableExclusiveUntil] = useState<boolean>(false)

  const [inclusiveTax, setInclusiveTax] = useState(false)
  const [bookingPriceInMills, setBookingPriceInMills] = useState<number | undefined>()
  const [disableBookingPrice, setDisableBookingPrice] = useState<boolean>(false)

  const [submitting, setSubmitting] = useState(false)
  const [changed, setChanged] = useState(false)

  useEffect(() => {
    if (booking && !isLoading) {
      if (exclusiveUntil) {
        setExclusiveUntil(DateTime.fromISO(booking.exclusiveUntil))
      }
      if (booking.priceInMillsOverride) {
        setBookingPriceInMills(booking.priceInMillsOverride)
      }
    }
  }, [isLoading])

  const handleUpdateOrganizationalBooking = async (bookingId: string) => {
    try {
      const priceInMills = Math.round(
        inclusiveTax ? bookingPriceInMills / 1.19 : bookingPriceInMills,
      )

      setSubmitting(true)
      await minDelay(
        qoursesApi.payment.paymentControllerPatchOrganizationalBooking(bookingId, {
          exclusiveUntil:
            disableExclusiveUntil && !exclusiveUntil ? null : exclusiveUntil.toISO(),
          priceInMillsOverride:
            disableBookingPrice && !priceInMills ? null : priceInMills,
        }),
        500,
      )
      queryClient.invalidateQueries(GetOrderBookingsQueryKey(booking.orderId))
      sendNotification(
        translate('pages.order-detail.bookings.notifications.update.title'),
        translate('pages.order-detail.bookings.notifications.update.subtitle'),
        ToastVariant.Success,
      )
    } catch (e) {
      console.error(e)
    } finally {
      setSubmitting(false)
      popAllModals()
    }
  }

  if (isError) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>
            {translate('modals.updateOrganizationalBookingModal.errorState.title')}
          </DialogTitle>
          <DialogDescription>
            {translate('modals.updateOrganizationalBookingModal.errorState.subtitle')}
          </DialogDescription>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  return (
    <Dynamic.Content className="p-4 sm:p-8">
      <DialogHeader className="mb-2">
        <DialogTitle className="mt-6 sm:mt-2">
          {translate('modals.updateOrganizationalBookingModal.title')}
        </DialogTitle>
        <DialogDescription>
          {translate('modals.updateOrganizationalBookingModal.subtitle')}
        </DialogDescription>
      </DialogHeader>
      <div
        className={cn(
          'mt-2 space-y-3 rounded-lg bg-gray-50 p-3 ring-1 ring-gray-100 transition-opacity',
          disableExclusiveUntil ? 'opacity-40' : '',
        )}
      >
        <div className="mb-4 flex items-center justify-between">
          <Label>
            {translate('modals.updateOrganizationalBookingModal.exclusiveTime.label')}
          </Label>
          <Button
            disabled={!booking?.exclusiveUntil || !exclusiveUntil}
            variant="secondary"
            size="sm"
            onClick={() => {
              setDisableExclusiveUntil(true)
              setChanged(true)
            }}
          >
            <X className="mr-1 size-4" />
            {translate(
              'modals.updateOrganizationalBookingModal.exclusiveTime.disableButton',
            )}
          </Button>
        </div>
        <div className="flex flex-col gap-y-4">
          <div>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    'w-[280px] justify-between bg-white text-left font-normal',
                    !exclusiveUntil && 'text-muted-foreground',
                  )}
                >
                  {booking && exclusiveUntil ? (
                    exclusiveUntil.toLocaleString(DateTime.DATETIME_MED)
                  ) : (
                    <span>
                      {translate('pages.create-course.steps.schedule.date.placeholder')}
                    </span>
                  )}
                  <CalendarIcon className="mr-2 h-4 w-4" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0">
                <Calendar
                  mode="single"
                  selected={exclusiveUntil?.toJSDate()}
                  onSelect={(date) => {
                    if (exclusiveUntil) {
                      setExclusiveUntil(
                        exclusiveUntil.set({
                          year: date.getFullYear(),
                          month: date.getMonth(),
                          day: date.getDate(),
                        }),
                      )
                    } else {
                      setExclusiveUntil(DateTime.fromJSDate(date))
                    }
                    setChanged(true)
                  }}
                  initialFocus
                  locale={locale}
                  disabled={{ before: new Date() }}
                />
              </PopoverContent>
            </Popover>
          </div>
          <TimePicker
            date={exclusiveUntil?.toJSDate()}
            setDate={(date) => {
              if (exclusiveUntil) {
                setExclusiveUntil(
                  exclusiveUntil.set({
                    hour: date.getHours(),
                    minute: date.getMinutes(),
                  }),
                )
              } else {
                setExclusiveUntil(DateTime.fromJSDate(date))
              }
              setChanged(true)
            }}
          />
        </div>
        <p className="text-xs text-gray-600">
          {translate('modals.updateOrganizationalBookingModal.exclusiveTime.subtitle')}
        </p>
      </div>
      {disableExclusiveUntil && (
        <div className="flex gap-x-1">
          <p className="text-sm text-muted-foreground">
            {translate('modals.updateOrganizationalBookingModal.exclusiveTime.disabled')}
          </p>
          <Button
            size="icon"
            variant="secondary"
            onClick={() => {
              setDisableExclusiveUntil(false)
              setExclusiveUntil(
                booking.exclusiveUntil
                  ? DateTime.fromISO(booking.exclusiveUntil)
                  : undefined,
              )
            }}
          >
            <Undo2 className="size-4" />
          </Button>
        </div>
      )}
      <div
        className={cn(
          'mt-2 space-y-3 rounded-lg bg-gray-50 p-3 ring-1 ring-gray-100 transition-opacity',
          disableBookingPrice ? 'opacity-40' : '',
        )}
      >
        <div className="mb-4 flex items-center justify-between">
          <Label>
            {translate('modals.updateOrganizationalBookingModal.priceOverride.label')}
          </Label>
          <Button
            disabled={!booking?.priceInMillsOverride || !bookingPriceInMills}
            variant="secondary"
            size="sm"
            onClick={() => {
              setDisableBookingPrice(true)
              setChanged(true)
            }}
          >
            <X className="mr-1 size-4" />
            {translate(
              'modals.updateOrganizationalBookingModal.priceOverride.disableButton',
            )}
          </Button>
        </div>
        <PriceInput
          setTaxIncluded={setInclusiveTax}
          taxIncluded={inclusiveTax}
          setPriceInMills={(price) => {
            setBookingPriceInMills(price)
            setChanged(true)
          }}
          priceInMills={bookingPriceInMills}
          title={''}
          subtitle={''}
        />
        <p className="text-xs text-gray-600">
          {translate('modals.updateOrganizationalBookingModal.priceOverride.subtitle')}
        </p>
      </div>
      {disableBookingPrice && (
        <div className="flex items-center gap-x-2">
          <p className="text-sm text-muted-foreground">
            {translate('modals.updateOrganizationalBookingModal.priceOverride.disabled')}
          </p>
          <Button
            size="icon"
            variant="secondary"
            onClick={() => {
              setDisableBookingPrice(false)
            }}
          >
            <Undo2 className="size-4" />
          </Button>
        </div>
      )}
      <div className="mt-4 flex flex-col justify-end">
        <Button
          variant="indigo"
          disabled={!changed}
          onClick={() => {
            handleUpdateOrganizationalBooking(bookingId)
          }}
        >
          {submitting ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {translate('common.loading')}
            </>
          ) : (
            translate('modals.updateOrganizationalBookingModal.button')
          )}
        </Button>
      </div>
    </Dynamic.Content>
  )
}
