import { useQuery } from '@tanstack/react-query'
import { qoursesApi } from '@/api/qourses.tsx'
import { MeetingEntity } from '../../../qourses-api-client'

export default function useGetScheduleBatchMeetings(scheduleBatchId: string): {
  meetings: MeetingEntity[]
  isLoading: boolean
  isEmptyMeetings: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: meetings,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(['scheduleBatch', scheduleBatchId, 'meetings'], async () => {
    return qoursesApi.scheduling.schedulingControllerGetScheduleBatchMeetings(
      scheduleBatchId,
    )
  })

  const isEmpty = !meetings || meetings.length === 0

  return {
    meetings: meetings || [],
    isLoading,
    isEmptyMeetings: isEmpty,
    isError,
    refetch,
    isRefetching,
  }
}
