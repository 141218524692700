import { useQuery } from '@tanstack/react-query'
import { qoursesApi } from '../../api/qourses.tsx'
import { ScheduleBatchEntity } from '../../../qourses-api-client'

export default function useGetScheduleBatches(): {
  scheduleBatches: ScheduleBatchEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: scheduleBatches,
    isLoading,
    isError,
  } = useQuery(getScheduleBatchesQueryKey(), async () => {
    return qoursesApi.scheduling.schedulingControllerGetScheduleBatches()
  })

  let isEmpty = true

  if (scheduleBatches === undefined) {
    return { scheduleBatches: [], isLoading, isEmpty, isError }
  }

  if (scheduleBatches.length > 0) {
    isEmpty = false
  }

  return { scheduleBatches, isLoading, isEmpty, isError }
}

export const getScheduleBatchesQueryKey = () => ['scheduleBatches']
