import { useQuery } from '@tanstack/react-query'
import { qoursesApi } from '../../api/qourses.tsx'
import { PublicInstructorEntity } from '../../../qourses-api-client'

export default function useGetMeetingInstructorsPublic(meetingId: string): {
  instructors: PublicInstructorEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: instructors,
    isLoading,
    isError,
  } = useQuery(['public', meetingId, 'instructors'], async () => {
    return qoursesApi.meetingPublic.meetingPublicControllerGetMeetingInstructors(
      meetingId,
    )
  })

  let isEmpty = true

  if (instructors === undefined) {
    return { instructors: [], isLoading, isEmpty, isError }
  }

  if (instructors.length > 0) {
    isEmpty = false
  }

  return { instructors, isLoading, isEmpty, isError }
}
