import {
  MeetingBookingInfoDto,
  OrderEntity,
  PublicCourseGroupWithBookingInfoEntity,
  PublicCourseWithPriceDetailsEntity,
  PublicMeetingWithBookingInfoEntity,
  PublicMultipassProductWithBookingInfoEntity,
} from '../../../../../qourses-api-client'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import useGetCourseMeetingsPublic from '../../../../hooks/public/useGetCourseMeetingsPublic.tsx'
import { motion } from 'framer-motion'
import { classNames } from '@/utils.tsx'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import EmptyState from '../../../../components/EmptyState.tsx'
import { match, P } from 'ts-pattern'
import { Lock, ShoppingCart, Ticket, UserRound, UsersRound } from 'lucide-react'
import { isMobile } from 'react-device-detect'
import useGetCourseGroupsPublic from '@/hooks/public/useGetCourseGroupsPublic.tsx'
import useGetCourseGroupMeetingsPublic from '@/hooks/public/useGetCourseGroupMeetingsPublic.tsx'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import { meetingIdParam } from '@/pages/public/booking/SessionParams.tsx'
import { Badge } from '@/shadcn/components/ui/badge.tsx'
import Highlight from '@/components/Highlight.tsx'
import NumberFlow from '@number-flow/react'
import useGetMeetingInstructorsPublic from '@/hooks/public/useGetMeetingInstructorsPublic.tsx'

export enum BookableType {
  Meeting = 'meeting',
  CourseGroup = 'courseGroup',
  Multipass = 'multipass',
}

export type IdentifiableBookable = {
  bookable:
    | PublicMeetingWithBookingInfoEntity
    | PublicCourseGroupWithBookingInfoEntity
    | PublicMultipassProductWithBookingInfoEntity
    | null
  bookableType: BookableType.Meeting | BookableType.CourseGroup | BookableType.Multipass
}

// Type guard for PublicMeetingWithBookingInfoEntity
export function isPublicMeetingWithBookingInfoEntity(
  bookable: any,
  bookableType: BookableType,
): bookable is PublicMeetingWithBookingInfoEntity {
  return bookableType === BookableType.Meeting && bookable !== null
}

// Type guard for PublicCourseGroupWithBookingInfoEntity
export function isPublicCourseGroupWithBookingInfoEntity(
  bookable: any,
  bookableType: BookableType,
): bookable is PublicCourseGroupWithBookingInfoEntity {
  return bookableType === BookableType.CourseGroup && bookable !== null
}

// Type guard for Multipass
export function isMultipassWithBookingInfoEntity(
  bookable: any,
  bookableType: BookableType,
): bookable is PublicMultipassProductWithBookingInfoEntity {
  return bookableType === BookableType.Multipass && bookable !== null
}

export function CourseMeetings(props: {
  course: PublicCourseWithPriceDetailsEntity
  setSelectedMeeting: Dispatch<SetStateAction<IdentifiableBookable>>
  selectedMeeting: IdentifiableBookable
  order: OrderEntity | null
}) {
  const [searchParams] = useSearchParams()
  const { meetings, isLoading: meetingsLoading } = useGetCourseMeetingsPublic(
    props.course.id,
  )

  const { courseGroups, isLoading: courseGroupsLoading } = useGetCourseGroupsPublic(
    props.course.id,
  )

  const { t: translate } = useTranslation()

  const meetingId = searchParams.get(meetingIdParam)

  // Hydrate the selected meeting from the URL
  useEffect(() => {
    if (meetingId) {
      const matchedMeeting =
        meetings.filter((meeting) => meeting.id === meetingId)[0] ?? null

      if (matchedMeeting) {
        props.setSelectedMeeting({
          bookable: matchedMeeting,
          bookableType: BookableType.Meeting,
        })
      }
    }
  }, [meetingsLoading])

  // Hydrate the selected courseGroup from the URL
  useEffect(() => {
    if (meetingId) {
      const matchedCourseGroup =
        courseGroups.filter((courseGroup) => courseGroup.id === meetingId)[0] ?? null

      if (matchedCourseGroup) {
        props.setSelectedMeeting({
          bookable: matchedCourseGroup,
          bookableType: BookableType.CourseGroup,
        })
      }
    }
  }, [courseGroupsLoading])

  if (meetingsLoading || courseGroupsLoading) {
    return (
      <div className="mb-5 mt-6 flex flex-1 gap-x-5">
        <div className="flex-1">
          <Skeleton className="h-[100px] w-full rounded-lg" />
        </div>
        <div className="flex-1">
          <Skeleton className="h-[100px] w-full rounded-lg" />
        </div>
      </div>
    )
  }

  if (meetings.length === 0 && courseGroups.length === 0) {
    return (
      <div className="mb-6 mt-6">
        <EmptyState
          title={translate('pages.public.booking.meeting-booking-info.empty-title')}
          subtitle={translate('pages.public.booking.meeting-booking-info.empty-subtitle')}
          isError={false}
        />
      </div>
    )
  }

  function hasFreeSpots(bookingInfo: MeetingBookingInfoDto, maxAttendees: number) {
    const freeSpots =
      maxAttendees - bookingInfo.claimed - bookingInfo.reserved - bookingInfo.booked

    return Math.round((freeSpots / maxAttendees) * 100) > 0
  }

  return (
    <div className="mb-8">
      {meetings.length > 0 && (
        <ul
          role="list"
          className="lg:grid-cols-auto grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6"
        >
          {meetings
            .filter((meeting) => !meeting.cancelled)
            .map((meeting) => (
              <>
                <motion.li
                  key={meeting.id}
                  className="col-span-1 flex cursor-pointer rounded-md opacity-100 shadow-sm"
                  initial={{ opacity: 0, translateY: -10 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  whileHover={{ scale: isMobile ? 1 : 1.05 }}
                  transition={{
                    type: 'spring',
                    bounce: 0.5,
                  }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => {
                    // If the meeting is booked out, can't select it!
                    // Probable future item here -> Waitinglist later
                    if (
                      !hasFreeSpots(meeting.bookingInfo, props.course.maximumAttendees)
                    ) {
                      return
                    }

                    // If the meeting is already selected, deselect it
                    if (
                      props.selectedMeeting &&
                      meeting.id === props.selectedMeeting.bookable.id
                    ) {
                      props.setSelectedMeeting(null)
                    } else {
                      props.setSelectedMeeting({
                        bookable: meeting,
                        bookableType: BookableType.Meeting,
                      })
                    }
                  }}
                >
                  <div
                    className={
                      classNames(
                        meeting?.id === props.selectedMeeting?.bookable.id
                          ? 'ring-2 ring-indigo-500'
                          : 'justify-start',
                        'group flex flex-1 items-center truncate rounded-md border border-gray-200 bg-violet-100/20 transition-colors',
                      ) +
                      //DON'T FORGET A SPACE HERE AT THE START OF THE CLASSNAME OR IT WILL BREAK
                      classNames(
                        hasFreeSpots(
                          meeting.bookingInfo,
                          props.course.maximumAttendees,
                        ) === false
                          ? 'disabled pointer-events-none cursor-not-allowed opacity-60'
                          : '',
                      )
                    }
                  >
                    <div className="ml-3 overflow-hidden rounded-lg bg-red-500 text-center shadow-inner ring-2 ring-gray-200">
                      <p className="px-2 py-0.5 text-xs font-semibold text-white">
                        {DateTime.fromISO(meeting.start).monthShort}
                      </p>
                      <div className="rounded-t-md bg-white px-1 text-gray-800">
                        {DateTime.fromISO(meeting.start).day}
                      </div>
                    </div>
                    <div className="flex-1 flex-wrap items-center truncate px-4 py-2 text-sm">
                      <div>
                        <div className="flex justify-between font-normal text-gray-900">
                          {translate('common.time.rangeShort', {
                            from: DateTime.fromISO(meeting.start).toLocaleString(
                              DateTime.TIME_SIMPLE,
                            ),
                            to: DateTime.fromISO(meeting.end).toLocaleString(
                              DateTime.TIME_SIMPLE,
                            ),
                          })}
                          {meeting.totalSavingsInMills > 0 ? (
                            <div className="relative">
                              <div className="flex items-center text-sm font-semibold">
                                <Ticket className="bezel mr-1 h-5 w-5 rounded-full bg-indigo-500 p-1 text-white" />
                                {translate('common.currency.EUR', {
                                  val: meeting.discountedTotalPriceInMills / 1000,
                                  minimumFractionDigits: 2,
                                })}
                              </div>
                              <div className="absolute right-0.5 text-xs line-through">
                                {translate('common.currency.EUR', {
                                  val: meeting.totalPriceInMills / 1000,
                                  minimumFractionDigits: 2,
                                })}
                              </div>
                            </div>
                          ) : (
                            <div className="flex text-sm font-semibold">
                              {translate('common.currency.EUR', {
                                val: meeting.totalPriceInMills / 1000,
                                minimumFractionDigits: 2,
                              })}
                            </div>
                          )}
                        </div>
                        <div className="mt-0.5 flex-col text-xs text-gray-500 md:flex md:flex-row">
                          {DateTime.fromISO(meeting.start).toLocaleString(
                            DateTime.DATE_MED,
                          )}
                        </div>
                        <div className="mt-1 text-xs">
                          <MeetingInstructors meetingId={meeting.id} />
                        </div>
                      </div>
                      <div className="mt-2 flex flex-wrap items-center justify-start gap-x-2 gap-y-2">
                        <AttendanceBadge
                          meeting={{
                            bookable: meeting,
                            bookableType: BookableType.Meeting,
                          }}
                          course={props.course}
                          order={props.order}
                        />
                      </div>
                    </div>
                  </div>
                </motion.li>
              </>
            ))}
        </ul>
      )}
      {courseGroups.length > 0 && (
        <>
          <div className="relative mt-8">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-white px-2 text-sm text-gray-500">Termingruppen</span>
            </div>
          </div>
          <ul role="list" className="mt-5 grid grid-cols-1 gap-5">
            {courseGroups.map((courseGroup) => (
              <>
                <motion.li
                  key={courseGroup.id}
                  className="col-span-1 flex cursor-pointer rounded-md shadow-sm"
                  initial={{ opacity: 0, translateY: -10 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  whileHover={{ scale: isMobile ? 1 : 1.02 }}
                  transition={{
                    type: 'spring',
                    bounce: 0.5,
                  }}
                  whileTap={{ scale: 0.99 }}
                  onClick={() => {
                    // If the meeting is booked out, can't select it!
                    // Probable future item here -> Waitinglist later
                    if (
                      !hasFreeSpots(
                        courseGroup.bookingInfo,
                        courseGroup.maximumAttendees ?? props.course.maximumAttendees,
                      )
                    ) {
                      return
                    }

                    // If the meeting is already selected, deselect it
                    if (
                      props.selectedMeeting &&
                      courseGroup.id === props.selectedMeeting.bookable.id
                    ) {
                      props.setSelectedMeeting(null)
                    } else {
                      props.setSelectedMeeting({
                        bookable: courseGroup,
                        bookableType: BookableType.CourseGroup,
                      })
                    }
                  }}
                >
                  <CourseGroupCard
                    course={props.course}
                    courseGroup={courseGroup}
                    isSelected={courseGroup?.id === props.selectedMeeting?.bookable.id}
                    attendanceBadge={
                      <AttendanceBadge
                        meeting={{
                          bookable: courseGroup,
                          bookableType: BookableType.CourseGroup,
                        }}
                        course={props.course}
                        order={props.order}
                      />
                    }
                  />
                </motion.li>
              </>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

function CourseGroupCard(props: {
  course: PublicCourseWithPriceDetailsEntity
  courseGroup: PublicCourseGroupWithBookingInfoEntity
  isSelected: boolean
  attendanceBadge: JSX.Element
}) {
  const courseGroup = props.courseGroup
  const { t: translate } = useTranslation()

  const { courseGroupMeetings, isLoading } = useGetCourseGroupMeetingsPublic(
    courseGroup.id,
  )

  function hasMeetingFreeSpots(
    courseGroup: PublicCourseGroupWithBookingInfoEntity,
    maxSpaces: number,
  ) {
    const freeSpots =
      maxSpaces -
      courseGroup.bookingInfo.claimed -
      courseGroup.bookingInfo.reserved -
      courseGroup.bookingInfo.booked

    return Math.round((freeSpots / maxSpaces) * 100) > 0
  }

  return (
    <div
      className={
        classNames(
          props.isSelected ? 'ring-2 ring-indigo-500' : 'justify-start gap-x-10',
          'group flex flex-1 items-center truncate rounded-md border border-gray-200 bg-violet-100/20 transition-colors',
        ) +
        //DON'T FORGET A SPACE HERE AT THE START OF THE CLASSNAME OR IT WILL BREAK
        classNames(
          hasMeetingFreeSpots(
            courseGroup,
            props.courseGroup.maximumAttendees ?? props.course.maximumAttendees,
          ) === false
            ? 'cursor-not-allowed opacity-60'
            : '',
        )
      }
    >
      <div className="text-md flex-1 truncate px-4 py-4">
        <div className="font-medium text-gray-900">
          <div className="sm:flex sm:justify-between">
            <div className="flex flex-wrap gap-x-2 whitespace-break-spaces">
              <p>{courseGroup.name}</p>
              <p className="text-muted-foreground">
                ({courseGroupMeetings.length} Termine)
              </p>
            </div>
            <div className="mb-2 sm:mb-0">
              {translate('common.currency.EUR', {
                val: courseGroup.totalPriceInMills / 1000,
                minimumFractionDigits: 2,
              })}
            </div>
          </div>
        </div>
        {isLoading ? (
          <></>
        ) : (
          <div className="flex max-w-md flex-wrap">
            {courseGroupMeetings
              .filter((meeting) => !meeting.cancelled)
              .map((meeting, index) => (
                <>
                  <div className="mr-1 mt-0.5 text-xs text-gray-500">
                    {DateTime.fromISO(meeting.start).toLocaleString(DateTime.DATE_SHORT)}{' '}
                    (
                    {DateTime.fromISO(meeting.start).toLocaleString(DateTime.TIME_SIMPLE)}
                    -{DateTime.fromISO(meeting.end).toLocaleString(DateTime.TIME_SIMPLE)})
                    {index < courseGroupMeetings.length - 1 && ','}
                  </div>
                </>
              ))}
          </div>
        )}
        <div className="mt-2 flex flex-wrap items-center justify-start gap-x-2 gap-y-2">
          {props.attendanceBadge}
        </div>
      </div>
    </div>
  )
}

function AttendanceBadge(props: {
  meeting: IdentifiableBookable
  course: PublicCourseWithPriceDetailsEntity
  order: OrderEntity | null
}) {
  const course = props.course
  const meeting = props.meeting

  const { t: translate } = useTranslation()

  const [highlight, setHighlight] = useState(false)

  let freeSpots

  if (isPublicMeetingWithBookingInfoEntity(meeting, BookableType.Meeting)) {
    freeSpots =
      course.maximumAttendees -
      meeting.bookable.bookingInfo.claimed -
      meeting.bookable.bookingInfo.reserved -
      meeting.bookable.bookingInfo.booked
  }

  if (isPublicCourseGroupWithBookingInfoEntity(meeting, BookableType.CourseGroup)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const maximumAttendees = meeting.bookable.maximumAttendees ?? course.maximumAttendees

    freeSpots =
      maximumAttendees -
      meeting.bookable.bookingInfo.claimed -
      meeting.bookable.bookingInfo.reserved -
      meeting.bookable.bookingInfo.booked
  }

  const freeSpotsPercentage = Math.round((freeSpots / course.maximumAttendees) * 100)

  freeSpots = Math.max(0, freeSpots)

  return (
    <>
      <Highlight
        trigger={freeSpots}
        duration={200}
        highlightCallback={(highlighting) => setHighlight(highlighting)}
        className="group flex items-center gap-x-1.5"
      >
        <motion.div
          animate={{
            scale: highlight ? 1.1 : 1,
          }}
          transition={{
            type: 'spring',
            bounce: 0.5,
          }}
        >
          <Badge
            variant="outline"
            className="gap-x-1 bg-white transition duration-1000 group-data-[highlight=on]:bg-white group-data-[highlight=on]:duration-150"
          >
            {match(freeSpotsPercentage)
              .with(P.number.between(90, 100), () => (
                <svg
                  className="h-1.5 w-1.5 fill-green-500"
                  viewBox="0 0 6 6"
                  aria-hidden="true"
                >
                  <circle cx={3} cy={3} r={3} />
                </svg>
              ))
              .with(P.number.between(60, 89), () => (
                <svg
                  className="h-1.5 w-1.5 fill-lime-500"
                  viewBox="0 0 6 6"
                  aria-hidden="true"
                >
                  <circle cx={3} cy={3} r={3} />
                </svg>
              ))
              .with(P.number.between(60, 89), () => (
                <svg
                  className="h-1.5 w-1.5 fill-yellow-500"
                  viewBox="0 0 6 6"
                  aria-hidden="true"
                >
                  <circle cx={3} cy={3} r={3} />
                </svg>
              ))
              .with(P.number.between(1, 59), () => (
                <svg
                  className="h-1.5 w-1.5 fill-orange-500"
                  viewBox="0 0 6 6"
                  aria-hidden="true"
                >
                  <circle cx={3} cy={3} r={3} />
                </svg>
              ))
              .otherwise(() => null)}
            {freeSpots == 0 ? (
              <Lock className="h-4 w-4 text-gray-400" />
            ) : (
              <>
                <div className="flex items-center">
                  <NumberFlow
                    value={freeSpots}
                    id={meeting.bookable.id + '-freeSpots'}
                    isolate={true}
                    className="mx-1"
                  />
                  {translate('pages.public.booking.badge.availability', {
                    count: freeSpots < 0 ? 0 : freeSpots,
                    claimed: freeSpots,
                  })}
                </div>
              </>
            )}
          </Badge>
        </motion.div>
      </Highlight>
      {meeting.bookable.bookingInfo.claimed > 0 && (
        <Badge variant="outline" className="bg-white">
          <ShoppingCart className="mr-1 h-4 w-4 text-gray-400" />
          <NumberFlow
            value={meeting.bookable.bookingInfo.claimed}
            id={meeting.bookable.id}
            isolate={true}
            className="mx-1"
          />
          {translate('pages.public.booking.meeting-booking-info.reserved-amount', {
            count: meeting.bookable.bookingInfo.claimed,
          })}
        </Badge>
      )}
    </>
  )
}

function MeetingInstructors(props: { meetingId: string }) {
  const { instructors, isLoading, isError } = useGetMeetingInstructorsPublic(
    props.meetingId,
  )

  if (isLoading) {
    return <Skeleton className="h-5 w-20 rounded-md" />
  }

  if (isError) {
    return <></>
  }

  return (
    <div className="flex">
      {instructors.length > 1 ? (
        <UsersRound className="mr-1 size-4 text-gray-500" />
      ) : (
        <UserRound className="mr-1 size-4 text-gray-500" />
      )}
      <div className="flex flex-wrap gap-x-1">
        Mit:
        <p className="font-medium">
          {instructors
            .map((instructor) => `${instructor.firstName} ${instructor.lastName}`)
            .join(', ')}
        </p>
      </div>
    </div>
  )
}
